<template>
   <div>
      <v-autocomplete
         v-model="model"
         :items="items"
         :loading="loading"
         :search-input.sync="search"
         chips
         clearable
         hide-details
         hide-selected
         item-text="text"
         item-value="text"
         :label="getTranslation('searchbar.searchFieldLabel')"
         class="search-bar selection-12px"
         color="error"
         no-filter
         ui-test-data="top-bar-search"
      >
         <template v-slot:no-data>
            <v-list-item>
               <v-list-item-content>
                  <v-list-item-title ui-test-data="top-bar-search-no-data">
                     {{ getTranslation("searchbar.searchHint") }}
                     <span>
                        <a @click="showHintDialog" class="red--text text--darken-3">
                           {{ getTranslation("searchbar.searchHintLink") }}
                        </a>
                     </span>
                  </v-list-item-title>
               </v-list-item-content>
            </v-list-item>
         </template>
         <template v-slot:selection="{ attr, on, item, selected }" class="search-bar">
            <v-chip
               v-bind="attr"
               :input-value="selected"
               color="red darken-4"
               class="white--text"
               v-on="on"
               @click="routeToRightUrl(item.url)"
            >
               <v-icon left>mdi-information</v-icon>
               <span v-text="item.text"></span>
            </v-chip>
         </template>
         <template v-slot:item="{ item, on, attrs }" class="search-bar">
            <v-list-item v-if="item.hasUserPerm" @click="onItemClick(item)">
               <v-list-item-avatar color="red darken-4" class="font-weight-light white--text">
                  {{ item.avatar }}
               </v-list-item-avatar>
               <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.code"></v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
            <v-list-item v-else @click="userHasNoPermissions">
               <v-list-item-avatar color="red darken-4" class="font-weight-light white--text">
                  {{ item.avatar }}
               </v-list-item-avatar>
               <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.code"></v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
         </template>
      </v-autocomplete>
      <v-dialog v-model="hintDialog" transition="dialog-bottom-transition" max-width="600">
         <v-card>
            <v-card-title primary-title>
               <span>Global search - hints</span>
               <v-spacer></v-spacer>
               <v-btn class="red--text" icon @click="clickClose">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
            </v-card-title>
            <v-card-text>
               <v-row>
                  <v-col>Using predefined keyword followed by colon, search can be narrowed to this entities:</v-col>
               </v-row>
               <v-row>
                  <v-col>
                     <v-simple-table dense>
                        <template v-slot:default>
                           <tbody>
                              <tr v-for="item in hintItems" :key="item.entity">
                                 <td>{{ getTranslation(`searchbar.helpDialogEntity${item.key}`) }}</td>
                                 <td>{{ getTranslation(`searchbar.helpDialogExample${item.key}`) }}</td>
                              </tr>
                           </tbody>
                        </template>
                     </v-simple-table>
                  </v-col>
               </v-row>
            </v-card-text>
         </v-card>
      </v-dialog>
   </div>
</template>

<script lang="ts">
import {
   GlobalSearchApi,
   GlobalSearchOptions,
   GlobalSearchResult,
   GlobalSearchResultEntity,
   GlobalSearchResultEntityType,
   TranslationPublicModel,
} from "@backend/api/pmToolApi";
import EventBus from "@backend/EventBus";
import globalStore from "@backend/store/globalStore";
import BaseResponse from "@models/BaseResponse";
import Events from "@models/shared/Events";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getTranslationValue } from "@utils/TranslationUtils";

/*

    Global Search functions have to provide access to projects, content models, data, etc. 
    From here Manuals and documentations, training materials, PM-Tool Wiki etc. must be accessible, too.

    */

@Component({
   name: "SearchBar",
   components: {},
})
export default class SearchBar extends Vue {
   loading: boolean = false;
   items: object[] = [];
   model: any = null;
   search: string = "";
   tab: any = null;
   timer;
   hintDialog: boolean = false;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   getTranslation(key: string): string {
      return getTranslationValue(key, this.translations);
   }

   routeToRightUrl(itemUrl: string, num) {
      this.$router.push({ path: itemUrl });
   }

   @Watch("search")
   onSearchValueChanged() {
      if (this.search) {
         this.search = this.search.trim();
         if (this.search.length > 2) {
            if (this.timer) {
               clearTimeout(this.timer);
            }
            var context = this;
            this.timer = setTimeout(() => {
               context.globalSearch();
            }, 500);
         }
      } else this.items = [];
   }

   @Watch("model")
   onModelValueChanged() {
      if (this.model != null) this.tab = 0;
      else this.tab = null;
   }

   async globalSearch(): Promise<void> {
      if (!this.search || this.search.length < 3) return;

      this.loading = true;
      try {
         let searchOptions = new GlobalSearchOptions({ text: this.search.trim() });
         let result = await GlobalSearchApi.search(globalStore.getDomain(), searchOptions);
         this.processSearchResult(result);
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API GlobalSearchApi search error:", error);
         EventBus.$emit(Events.DisplayToast, {
            color: "error",
            text: `Failed to GlobalSearch search: ${error?.message}`,
         });
      }
      this.loading = false;
   }

   processSearchResult(result: GlobalSearchResult) {
      this.items = result.entities.map((e) => ({
         id: e.entityIdentifier,
         avatar: e.entityTypeText,
         text: e.displayText,
         code: e.entityCode,
         hasUserPerm: e.hasUserPermissions,
         domain: e.entityDomain,
         url: this.getEntityDetailUrl(e),
      }));
   }

   onItemClick(item: object) {
      if (item.domain != null) {
         globalStore.setDomain(item.domain);
         EventBus.$emit(Events.ChangeDomain);
         EventBus.$emit(Events.ReloadDomains);
      }
      this.$router.push({ path: item.url });
   }

   userHasNoPermissions() {
      EventBus.$emit(Events.DisplayToast, {
         color: "error",
         text: `User has no permissions for this object`,
      });
   }

   getEntityDetailUrl(entity: GlobalSearchResultEntity) {
      let entityIdentifier = entity.entityIdentifier;
      switch (entity.entityType) {
         case GlobalSearchResultEntityType.Group:
            return `/groups/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Qualification:
            return `/qualifications/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Organigram:
            return `/user/orgChart/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Dm:
            return `/generalDataModels/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Ddm:
            return `/domainDataModels/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.DdmInstance:
            return `/domainDataModelInstances/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Cb:
            return `/contentBricks/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Scb:
            return `/subContentBricks/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Dgl:
            return `/designGuidelines/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.PracticalTest:
            return `/practicalTests/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.GeneralTest:
            return `/generalTests/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.NormsAndStandards:
            return `/normsAndStandards/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.MaterialCompliance:
            return `/materialCompliance/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.PreShipmentInstructions:
            return `/preShipmentInstructions/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Pbb:
            return `/pbb/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Project:
            return `/project/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.ProjectDraft:
            return `/projectDraft/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Tag:
            return `/tag/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Unit:
            return `/units/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.UnitType:
            return `/unitTypes/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.UsageType:
            return `/usageTypes/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.PublicHoliday:
            return `/publicHolidays/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Report:
            return `/report/grid/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.Capability:
            return `/capabilities/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.List:
            return `/list/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.User:
            return `/user/detail/${entityIdentifier}`;
         case GlobalSearchResultEntityType.AutomaticImport:
            return `/automaticImports/detail/${entityIdentifier}`;
         default:
            throw new Error(`search-bar: Not supported entity type ${entity.entityTypeText}`);
      }
   }

   hintItems = [
      { entity: "PBB", hint: "pbb:drill", key: "PBB" },
      { entity: "Content Brick", hint: "cb:drill", key: "ContentBrick" },
      { entity: "Design Guideline", hint: "dgl:drill", key: "DesignGuideline" },
      { entity: "Practical Test", hint: "prt:drill", key: "PracticalTest" },
      { entity: "General Test", hint: "get:drill", key: "GeneralTest" },
      { entity: "Norms And Standards", hint: "nas:drill", key: "NormsAndStandards" },
      { entity: "Material Compliance", hint: "mco:drill", key: "MaterialCompliance" },
      { entity: "Pre-shipment Instructions", hint: "pin:drill", key: "PreShipmentInstructions" },
      { entity: "Sub Content Brick", hint: "scb:drill", key: "SubContentBrick" },
      { entity: "Domain Data Models", hint: "ddm:drill", key: "DomainDataModels" },
      { entity: "Domain Data Model Instances", hint: "dmi:drill", key: "DomainDataModelInstances" },
      { entity: "General Data Models", hint: "gdm:drill", key: "GeneralDataModels" },
      { entity: "Projects", hint: "prj:drill", key: "Projects" },
      { entity: "Users", hint: "user:hans", key: "Users" },
      { entity: "User groups", hint: "group:admin", key: "UserGroups" },
      { entity: "Tags", hint: "tag:cool", key: "Tags" },
      { entity: "Units", hint: "unit:meter", key: "Units" },
      { entity: "Unit types", hint: "utype:length", key: "UnitTypes" },
      { entity: "Usage type", hint: "usage:what", key: "UsageTypes" },
      { entity: "Capability", hint: "cap:office", key: "Capability" },
      { entity: "Qualification", hint: "qual:excel", key: "Qualification" },
      { entity: "List", hint: "list:days", key: "List" },
      { entity: "Automatic Import", hint: "imp:project", key: "AutomaticImport" },
   ]
      .map((item) => ({
         ...item,
         translatedEntity: this.getTranslation(`searchbar.helpDialogEntity${item.key}`),
      }))
      .sort((a, b) => (a.translatedEntity.toLowerCase() < b.translatedEntity.toLowerCase() ? -1 : 1));

   clickClose() {
      this.hideHintDialog();
   }

   showHintDialog() {
      this.hintDialog = true;
   }

   hideHintDialog() {
      this.hintDialog = false;
   }
}
</script>

<style scoped>
.search-bar {
   z-index: 500;
}

.avatar-text {
   font-size: 5px;
}
</style>
<style lang="scss">
.selection-12px.v-input.v-select {
   .v-select__slot {
      line-height: 18px;

      > label.v-label {
         height: 16px;
         line-height: 16px;
         top: 8px;
      }

      > .v-select__selections {
         min-height: 28px;

         > input {
            max-height: 28px;
            padding-top: 8px;
            padding-bottom: 4px;
         }
      }

      > div.v-input__append-inner {
         font-size: 12px;
      }
   }

   div.v-input__append-outer {
      font-size: 12px;
      margin-top: 0px;
      margin-bottom: 0px;
   }
}
</style>
