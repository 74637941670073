import { ContentBrickType } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IContentBrickTypeProperties extends IEnumBaseProperties {
   listRouteName: string;
   detailRouteName: string;
   newItemRouteName: string;
   legacyLabel: string;
   icon: `mdi-${string}`;
   color: string;
   cardClass: string;
   hasValueFields: boolean;
}

export class ContentBrickTypeDecorator implements IContentBrickTypeProperties {
   value: ContentBrickType;
   private static readonly unkown: number = -1;

   constructor(value: ContentBrickType) {
      this.value = value;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   get listRouteName(): string {
      return this.properties.listRouteName;
   }

   get detailRouteName(): string {
      return this.properties.detailRouteName;
   }

   get newItemRouteName(): string {
      return this.properties.newItemRouteName;
   }

   get legacyLabel(): string {
      return this.properties.legacyLabel;
   }

   get icon() {
      return this.properties.icon;
   }

   get color(): string {
      return this.properties.color;
   }

   get cardClass(): string {
      return this.properties.cardClass;
   }

   get hasValueFields(): boolean {
      return this.properties.hasValueFields;
   }

   static get AllItems(): ContentBrickTypeDecorator[] {
      return Array.from(ContentBrickTypeDecorator.ContentBrickTypeProperties.keys())
         .filter((status: number) => status != ContentBrickTypeDecorator.unkown)
         .map((status: number) => new ContentBrickTypeDecorator(status));
   }

   static get(value: ContentBrickType): IContentBrickTypeProperties {
      return {
         ...(ContentBrickTypeDecorator.ContentBrickTypeProperties.get(value) ??
            ContentBrickTypeDecorator.ContentBrickTypeProperties.get(ContentBrickTypeDecorator.unkown)!),
      };
   }

   private get properties(): IContentBrickTypeProperties {
      return (
         ContentBrickTypeDecorator.ContentBrickTypeProperties.get(this.value) ??
         ContentBrickTypeDecorator.ContentBrickTypeProperties.get(ContentBrickTypeDecorator.unkown)!
      );
   }

   private static readonly ContentBrickTypeProperties: Map<number, IContentBrickTypeProperties> = new Map<
      number,
      IContentBrickTypeProperties
   >([
      [
         ContentBrickType.ContentBrick,
         {
            translationKey: "contentBrickType.contentBrick",
            icon: "mdi-cube",
            color: "initial",
            cardClass: "pmtool-card-content-brick",
            legacyLabel: "Content Brick",
            listRouteName: "content-brick-list",
            detailRouteName: "content-brick-detail",
            newItemRouteName: "content-brick-detail-new",
            hasValueFields: false,
         },
      ],
      [
         ContentBrickType.DesignGuideline,
         {
            translationKey: "contentBrickType.designGuideline",
            icon: "mdi-cube-scan",
            color: "brown",
            cardClass: "pmtool-card-design-guideline",
            legacyLabel: "Design Guideline",
            listRouteName: "design-guideline-list",
            detailRouteName: "design-guideline-detail",
            newItemRouteName: "design-guideline-detail-new",
            hasValueFields: true,
         },
      ],
      [
         ContentBrickType.PracticalTest,
         {
            translationKey: "contentBrickType.practicalTest",
            icon: "mdi-test-tube-empty",
            color: "red",
            cardClass: "pmtool-card-practical-test",
            legacyLabel: "Practical Test",
            listRouteName: "practical-test-list",
            detailRouteName: "practical-test-detail",
            newItemRouteName: "practical-test-detail-new",
            hasValueFields: true,
         },
      ],
      [
         ContentBrickType.GeneralTest,
         {
            translationKey: "contentBrickType.generalTest",
            icon: "mdi-flask-empty-outline",
            color: "red",
            cardClass: "pmtool-card-general-test",
            legacyLabel: "General Test",
            listRouteName: "general-test-list",
            detailRouteName: "general-test-detail",
            newItemRouteName: "general-test-detail-new",
            hasValueFields: true,
         },
      ],
      [
         ContentBrickType.NormsAndStandards,
         {
            translationKey: "contentBrickType.normsAndStandards",
            icon: "mdi-certificate",
            color: "red",
            cardClass: "pmtool-card-practical-test",
            legacyLabel: "Norms And Standards",
            listRouteName: "norms-and-standards-list",
            detailRouteName: "norms-and-standards-detail",
            newItemRouteName: "norms-and-standards-detail-new",
            hasValueFields: true,
         },
      ],
      [
         ContentBrickType.MaterialCompliance,
         {
            translationKey: "contentBrickType.materialCompliance",
            icon: "mdi-ruler",
            color: "red",
            cardClass: "pmtool-card-practical-test",
            legacyLabel: "Material Compliance",
            listRouteName: "material-compliance-list",
            detailRouteName: "material-compliance-detail",
            newItemRouteName: "material-compliance-detail-new",
            hasValueFields: true,
         },
      ],
      [
         ContentBrickType.PreShipmentInstructions,
         {
            translationKey: "contentBrickType.preShipmentInstructions",
            icon: "mdi-package-variant",
            color: "red",
            cardClass: "pmtool-card-practical-test",
            legacyLabel: "Pre-shipment Instructions",
            listRouteName: "pre-shipment-instructions-list",
            detailRouteName: "pre-shipment-instructions-detail",
            newItemRouteName: "pre-shipment-instructions-detail-new",
            hasValueFields: true,
         },
      ],
      [
         ContentBrickTypeDecorator.unkown,
         {
            translationKey: "contentBrickType.unknown",
            icon: "mdi-help-circle-outline",
            color: "grey",
            cardClass: "pmtool-card-content-brick",
            legacyLabel: "Unknown",
            listRouteName: "",
            detailRouteName: "",
            newItemRouteName: "",
            hasValueFields: false,
         },
      ],
   ]);
}
