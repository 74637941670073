import { ContentBrickFieldType, IContentBrickFieldDefinition } from "@backend/api/pmToolApi";
import QueryBuilderRule from "@models/shared/query-builder/QueryBuilderRule";
import { ContentBrickFieldTypeDecorator } from "@models/shared/ContentBrickFieldTypeDecorator";

export default class ContentBrickDefinitionUtils {
   static queryFieldRules(fields: IContentBrickFieldDefinition[]): QueryBuilderRule[] {
      let rules = fields.map((field) => {
         var fieldType = new ContentBrickFieldTypeDecorator(field.type);
         return {
            id: field.id,
            label: field.name!,
            type: fieldType.queryBuilderType,
            fields: fields
               .filter(
                  (of) =>
                     of.id !== field.id &&
                     this.isFieldComparableTo(fieldType, new ContentBrickFieldTypeDecorator(of.type))
               )
               .map((field) => ({
                  id: field.id,
                  label: field.name,
               })),
            ...(fieldType.queryOperators && {
               operators: fieldType.queryOperators,
            }),
            ...(fieldType.value === ContentBrickFieldType.ComboBox && {
               choices: ContentBrickDefinitionUtils.getListChoices(field),
            }),
            ...(fieldType.value === ContentBrickFieldType.List && {
               choices: ContentBrickDefinitionUtils.getListChoices(field),
            }),
         };
      });
      return rules;
   }

   static getListChoices(field: IContentBrickFieldDefinition) {
      let list = field.list;
      if (list) {
         if (list.isMultiColumn) {
            return list.items?.map((li) => ({
               label: Object.values(li.columns).join(", "),
               value: JSON.stringify(li.columns),
            }));
         } else {
            return list.items?.map((li) => ({ label: li.text, value: li.identifier, disabled: li.disabled }));
         }
      }
      return [];
   }

   static isFieldComparableTo(fieldA: ContentBrickFieldTypeDecorator, fieldB: ContentBrickFieldTypeDecorator): boolean {
      if (fieldA.value === ContentBrickFieldType.ComboBox || fieldA.value === ContentBrickFieldType.List) {
         // combobox value is comparable to strings
         return fieldB.queryBuilderType === "text";
      }
      if (fieldA.value === ContentBrickFieldType.Date || fieldA.value === ContentBrickFieldType.DateTime) {
         // Date + DateTime values are comparable with each other
         return fieldB.value === ContentBrickFieldType.Date || fieldB.value === ContentBrickFieldType.DateTime;
      }
      return fieldA.queryBuilderType === fieldB.queryBuilderType; // input type is the same
   }
}
