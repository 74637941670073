<template>
   <v-row justify="center">
      <v-col cols="12">
         <div class="px-6">
            <v-sheet class="d-flex justify-center">
               <h2 class="text-h2">{{ designGuideline.name }}</h2>
            </v-sheet>
            <div>
               <h5 class="text-h5">{{ translateKey("contentTab.summaryLabel", translations) }}</h5>
               <div v-html="designGuideline.description?.value"></div>
            </div>
            <v-row>
               <v-col cols="auto" class="d-flex align-center">
                  <span class="text-h5">{{ translateKey("contentTab.tagsFieldLabel", translations) }}</span>
               </v-col>
               <v-col>
                  <v-chip-group>
                     <v-chip v-for="tag in designGuideline.tags" :key="tag.id" class="tag">
                        {{ tag.displayText }}
                     </v-chip>
                  </v-chip-group>
               </v-col>
            </v-row>
            <design-guideline-field-group
               :parentGroup="designGuideline.contentBrickFieldGroupTree"
               :fields="[...designGuideline.fields, ...designGuideline.designGuidelineValueFields]"
               :attachments="attachments"
               :mediaUrls="mediaUrls"
               :contentBrick="designGuideline"
               :attachmentsLoading="mediaLoading"
            ></design-guideline-field-group>
            <h5 v-if="metadataFields.length > 0" class="text-h5">
               {{ translateKey("contentTab.metadataFieldsLabel", translations) }}
            </h5>
            <design-guideline-field
               v-for="node in metadataFields"
               class="field-group-child-field"
               :key="node.id"
               :field="node"
               :attachments="attachments"
               :mediaUrls="mediaUrls"
               :contentBrick="designGuideline"
               :attachmentsLoading="mediaLoading"
            />
         </div>
      </v-col>
   </v-row>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import {
   AttachmentDownloadModel,
   AttachmentMetadata,
   ContentBrickDefinitionApi,
   IContentBrickDefinition,
   TranslationPublicModel,
} from "@backend/api/pmToolApi";
import DesignGuidelineFieldGroup from "@components/ContentBricks/SimpleView/design-guideline-field-group.vue";
import DesignGuidelineField from "@components/ContentBricks/SimpleView/design-guideline-field.vue";
import BaseResponse from "@models/BaseResponse";
import AttachmentUtils from "@utils/AttachmentUtils";

@Component({
   name: "DesignGuidelineSimpleView",
   components: { DesignGuidelineFieldGroup, DesignGuidelineField },
})
export default class DesignGuidelineSimpleView extends ComponentBase {
   @Prop({ required: true })
   designGuideline: IContentBrickDefinition;

   @Prop({ required: true })
   attachments: { [key: string]: AttachmentMetadata } | undefined;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   mounted() {
      this.loadMedia();
   }

   mediaPreloading: number = 0;
   mediaUrls: { [key: string]: string } = {};

   get mediaLoading(): boolean {
      return !!this.mediaPreloading;
   }

   get metadataFields() {
      const result = [...(this.designGuideline.designGuidelineMetadataFields ?? [])];
      result.sort((a, b) => {
         return a.order - b.order;
      });
      return result;
   }

   async loadMedia() {
      try {
         this.mediaPreloading++;
         this.designGuideline?.designGuidelineValueFields
            ?.filter((f) => AttachmentUtils.isMediaField(f.type))
            .forEach(async (f) => {
               if (AttachmentUtils.isMediaField(f.type)) {
                  let mediaBlobs = f.value?.filter((url) => !this.mediaUrls[url]) ?? [];
                  await this.downloadMediaUrls(mediaBlobs);
               }
            });
      } catch (e) {
         let error = e as BaseResponse;
         console.log("Preloading attachment media error:", error);
         this.notifyError(error, "preload", "attachment media");
      } finally {
         this.mediaPreloading--;
      }
   }

   async downloadMediaUrls(mediaBlobs: string[]): Promise<void> {
      if (!mediaBlobs || !mediaBlobs.length) {
         return;
      }

      try {
         this.mediaPreloading++;

         for (let value of mediaBlobs) {
            var file = await this.generateMediaLink(value);

            if (file) {
               this.$set(this.mediaUrls, file.blobName!, file.downloadUrl!);
            }
         }
      } finally {
         this.mediaPreloading--;
      }
   }

   async generateMediaLink(blobName: string): Promise<AttachmentDownloadModel | undefined> {
      if (!blobName) {
         throw "Blob name is required";
      }

      try {
         this.mediaPreloading++;
         // Call the API FileResponse
         let file = ContentBrickDefinitionApi.generateAttachmentDownloadUrl(this.designGuideline!.id, blobName);
         return file;
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API DesignGuideline attachment download error:", error);
         this.notifyError(error, "download", "DesignGuideline Attachment");
      } finally {
         this.mediaPreloading--;
      }
   }
}
</script>

<style scoped>
.tag {
   cursor: initial;
}
</style>
