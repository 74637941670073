<template>
   <div>
      <spinner v-if="loading" />
      <v-container v-else fluid>
         <detail-header
            v-if="subContentBrick"
            :entity="subContentBrick"
            :title="title"
            :isNew="isNew"
            :deleteFunc="deleteSubContentBrickDefinition"
            :originalStatus="originalStatus"
            :isFromAdministration="false"
            :currentTab="selectedTab"
            :canDelete="canDelete"
            :canChangeStatus="canEdit"
         ></detail-header>
         <v-row>
            <v-col>
               <div class="detail-tab-menu-header-container" elevation="3">
                  <v-tabs
                     color="error"
                     v-model="selectedTab"
                     centered
                     icons-and-text
                     background-color="rgb(245,245,245)"
                     show-arrows
                  >
                     <v-tabs-slider></v-tabs-slider>
                     <v-tab
                        v-for="tab in detailTabs"
                        :key="tab.id"
                        :disabled="!isTabEnabled(tab.id)"
                        v-on="tab.onActivated ? { change: tab.onActivated } : {}"
                     >
                        {{ tab.title }}
                        <v-icon color="red">{{ tab.icon }}</v-icon>
                     </v-tab>
                  </v-tabs>
                  <v-toolbar dense floating v-if="subContentBrick">
                     <back-btn fallback-route="/subContentBricks/overview"></back-btn>
                     <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              :disabled="!canSave()"
                              v-shortkey.once="['ctrl', 's']"
                              @shortkey="upsertSubContentBrickDefinition()"
                              @click="upsertSubContentBrickDefinition()"
                           >
                              <v-icon dense color="success">mdi-content-save</v-icon>
                           </v-btn>
                        </template>
                        <span>{{ translateKey("detailView.saveTooltip") }}</span>
                     </v-tooltip>
                     <create-new-item-btn
                        v-if="canCreate"
                        new-item-route="/subContentBricks/new"
                        :tooltip-title="translateKey('subContentBrickDetail.subContentBrick')"
                        :translations="routeTranslations"
                     />
                     <v-tooltip top v-if="canCreateDraft">
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              v-shortkey.once="['ctrl', 'd']"
                              @shortkey="createSubContentBrickDefinitionDraft()"
                              @click="createSubContentBrickDefinitionDraft()"
                           >
                              <v-icon dense color="success">mdi-content-duplicate</v-icon>
                           </v-btn>
                        </template>
                        <span>{{ translateKey("detailView.newDraftTooltip") }}</span>
                     </v-tooltip>
                  </v-toolbar>
               </div>
               <v-tabs-items v-if="subContentBrick" v-model="selectedTab">
                  <v-tab-item>
                     <v-card>
                        <v-form ref="contentForm">
                           <sub-content-brick-detail-tab-content
                              ref="contentTab"
                              :subContentBrick="subContentBrick"
                              :isNew="isNew"
                              :originalStatus="originalStatus"
                              :translations="routeTranslations"
                           ></sub-content-brick-detail-tab-content>
                        </v-form>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <sub-content-brick-detail-tab-cb-usage
                        :subContentBrick="subContentBrick"
                        :translations="routeTranslations"
                     ></sub-content-brick-detail-tab-cb-usage>
                  </v-tab-item>
                  <v-tab-item>
                     <version-table-tab
                        :versions="subContentBrick.availableVersions"
                        :translations="routeTranslations"
                     ></version-table-tab>
                  </v-tab-item>
               </v-tabs-items>
            </v-col>
         </v-row>
      </v-container>
      <confirm-close-dialog-async ref="confirmCloseDialog"></confirm-close-dialog-async>
      <version-note-dialog ref="versionNoteDialog"></version-note-dialog>
   </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";

import Spinner from "@components/Shared/spinner.vue";
import VersionNoteDialog from "@components/Shared/version-note-dialog.vue";
import ConfirmCloseDialogAsync from "@components/Shared/confirm-close-dialog-async.vue";
import ChangeWatcher from "@utils/ChangeWatcher";
import DetailHeader from "@components/Shared/detail-header.vue";
import VersionTableTab from "@components/Shared/version-table-tab.vue";
import BackBtn from "@components/Shared/back-btn.vue";
import CreateNewItemBtn from "@components/Shared/create-new-item-btn.vue";

import BaseResponse from "@models/BaseResponse";
import GlobalStore from "@backend/store/globalStore.ts";
import { EntityStatusDecorator } from "@models/shared/EntityStatusDecorator";
import {
   SubContentBrickDefinitionApi,
   SubContentBrickDefinition,
   ISubContentBrickDefinition,
   EntityStatus,
   VersionedDocumentStatusChange,
   EntityType,
   ContentBrickConditionResultsIn,
   ContentBrickExpression,
   ContentBrickExpressionType,
} from "@backend/api/pmToolApi";
import Events from "@models/shared/Events";
import EventBus from "@backend/EventBus";
import filterStore from "@backend/store/filterStore";
import DetailBase from "@components/Shared/Base/detail-base.vue";
import SubContentBrickDetailTabContent from "@components/ContentBricks/SubCbTabDetail/sub-content-brick-detail-tab-content.vue";
import SubContentBrickDetailTabCbUsage from "@components/ContentBricks/SubCbTabDetail/sub-content-brick-detail-tab-cb-usage.vue";
import DomainUtils from "@utils/DomainUtils";

@Component({
   name: "SubContentBrickDetail",
   components: {
      Spinner,
      VersionNoteDialog,
      ConfirmCloseDialogAsync,
      DetailHeader,
      VersionTableTab,
      BackBtn,
      CreateNewItemBtn,
      SubContentBrickDetailTabContent,
      SubContentBrickDetailTabCbUsage,
   },
})
export default class SubContentBrickDetail extends DetailBase {
   id: string = "";
   code: string = "";
   loading: boolean = false;
   isNew: boolean = false;
   routeNames: string[] = [
      "sub-content-brick-detail",
      "content-brick-field-type-decorator",
      "content-brick-type-decorator",
      "detail-view",
   ];

   subContentBrick: ISubContentBrickDefinition | null = null;
   originalStatus: EntityStatus | null = null;

   get draftId(): string | undefined {
      return this.subContentBrick?.version?.draftId;
   }

   get title(): string {
      return this.isNew ? "New Sub Content Brick" : this.subContentBrick?.name ?? "Unknown Sub Content Brick";
   }

   canSave(): boolean {
      if (!this.isNew && this.subContentBrick?.permissions?.update !== true) {
         return false;
      }

      if (this.subContentBrick && this.originalStatus !== this.subContentBrick.entityStatus) {
         return true;
      }

      if (this.originalStatus !== EntityStatus.Draft) {
         return false;
      }

      return this.hasChanges;
   }

   get canEdit(): boolean {
      return (
         this.originalStatus === EntityStatus.Draft &&
         (this.isNew || this.subContentBrick?.permissions?.update === true)
      );
   }

   get canCreateDraft(): boolean {
      return (
         !this.isNew && this.originalStatus !== EntityStatus.Draft && this.subContentBrick?.permissions?.update === true
      );
   }

   get canCreate(): boolean {
      return this.isNew || this.subContentBrick?.permissions?.create === true;
   }

   get canDelete(): boolean {
      return !this.isNew && this.subContentBrick?.permissions?.delete === true;
   }

   get AllStates(): EntityStatusDecorator[] {
      return EntityStatusDecorator.AllItems;
   }

   async upsertSubContentBrickDefinition(isNavigating: boolean = false): Promise<void> {
      let versionNote: string | null = null;

      if (!this.subContentBrick) return;

      // status changed 'Draft' -> non-draft
      if (
         (this.originalStatus === EntityStatus.Draft || this.isNew) &&
         this.subContentBrick.entityStatus !== EntityStatus.Draft
      ) {
         if (this.subContentBrick.version?.major > 1) {
            //if not the first version
            let res = await this.$refs.versionNoteDialog.show();
            if (!res.result) {
               return;
            }
            versionNote = res.versionNote;
         } else {
            versionNote = "Initial version"; // add some default value
         }
      }

      if (this.isNew) {
         await this.createSubContentBrickDefinition(isNavigating, versionNote);
      }
      // status changed to 'Draft' on non-draft item, create new draft instead of saving
      else if (this.subContentBrick.entityStatus === EntityStatus.Draft && this.originalStatus !== EntityStatus.Draft) {
         await this.createSubContentBrickDefinitionDraft(isNavigating);
      } else {
         await this.updateSubContentBrickDefinition(versionNote);
      }
   }

   // -------- Save guard -------------
   hasChanges: boolean = false;
   isRouteGuarded: boolean = true;

   async beforeRouteLeave(to, from, next): Promise<void> {
      await ChangeWatcher.onRouteChange(next, this, () => this.upsertSubContentBrickDefinition(true), to);
   }

   async beforeRouteUpdate(to, from, next): Promise<void> {
      await ChangeWatcher.onRouteChange(next, this, () => this.upsertSubContentBrickDefinition(true), to);
   }

   // -------- Tabs control -------------
   selectedTab: number = 0;

   detailTabs = [
      { id: 0, icon: "mdi-details", title: "Content", translationKey: "subContentBrickDetail.contentTab" },
      {
         id: 1,
         icon: "mdi-debug-step-into",
         title: "CB References",
         translationKey: "subContentBrickDetail.cbReferencesTab",
      },
      { id: 2, icon: "mdi-history", title: "History", translationKey: "subContentBrickDetail.historyTab" },
   ];

   isTabEnabled(tabId: number): boolean {
      return tabId === 0 || !this.isNew;
   }

   // ---------- Validation ------------
   validate(): boolean {
      // Content
      if (!this.$refs.contentForm.validate() || !this.$refs.contentTab.validate()) {
         return false;
      }

      return true;
   }

   // ---- API -----
   setSubContentBrick(subContentBrick: SubContentBrickDefinition) {
      this.id = subContentBrick.id;
      this.code = subContentBrick.code!;
      this.subContentBrick = subContentBrick;
      this.originalStatus = subContentBrick.entityStatus;
      this.updateWindowTitle(`${subContentBrick.code}`);

      DomainUtils.setDomainFromList(this.subContentBrick.domains);
   }

   async loadSubContentBrickDefinition(): Promise<void> {
      this.loading = true;
      try {
         // Call the API
         let subContentBrick: SubContentBrickDefinition;
         if (this.id) {
            subContentBrick = await SubContentBrickDefinitionApi.getSubContentBrickDefinition(this.id);
         } else {
            subContentBrick = await SubContentBrickDefinitionApi.getSubContentBrickDefinitionByCode(this.code);
         }
         //replace Project Code in URL to match the usual schema with :id
         if (!this.id) {
            history.replaceState(
               history.state?.data ?? {},
               history.state?.title ?? "",
               `/subContentBricks/detail/${subContentBrick.id}`
            );
         }
         // Process/Save data etc.
         this.setSubContentBrick(subContentBrick);
      } catch (e) {
         let error = e as BaseResponse;
         console.warn("API SubContentBrickDefinition load error:", error);
         this.notifyError(error, "load", "Sub Content Brick");
      }
      this.loading = false;
   }

   async createSubContentBrickDefinition(isNavigating: boolean = false, versionNote?: string | null): Promise<void> {
      if (!this.subContentBrick) throw "No SubContentBrickDefinition defined";

      if (this.subContentBrick.entityStatus != EntityStatus.Draft) {
         if (!this.validate()) {
            this.notifyError({ message: "Validation failed.", success: false }, "validate", "Sub Content Brick");
            return;
         }
      }

      this.loading = true;
      try {
         // Call the API
         let subContentBrick = await SubContentBrickDefinitionApi.createSubContentBrickDefinition(
            new SubContentBrickDefinition(this.subContentBrick),
            versionNote
         );
         // Process/Save data etc.
         EventBus.$emit(Events.DisplayToast, {
            color: "success",
            text: "Content Brick has been created",
         });
         if (!isNavigating) {
            this.isRouteGuarded = false; // turn guards off in order to prevent infinite loop
            this.$router.replace({
               name: "sub-content-brick-detail",
               params: {
                  id: subContentBrick.id,
               },
            });
         }
      } catch (e) {
         let error = e as BaseResponse;
         console.warn("API SubContentBrickDefinition create error:", error);
         this.notifyError(error, "create", "Sub Content Brick");
      }
      this.loading = false;
   }

   async createSubContentBrickDefinitionDraft(isNavigating: boolean = false): Promise<void> {
      if (!this.subContentBrick) throw "No SubContentBrickDefinition defined";

      if (this.draftId != null) {
         this.notifyInfo("Draft is already created.");
         this.$router.push({
            name: "sub-content-brick-detail",
            params: {
               id: this.draftId,
               restoreTab: this.selectedTab,
            },
         });
         return;
      }

      this.loading = true;
      try {
         // Call the API
         let subContentBrick = await SubContentBrickDefinitionApi.createSubContentBrickDefinitionDraft(
            this.subContentBrick.id
         );
         // Process/Save data etc.
         EventBus.$emit(Events.DisplayToast, {
            color: "success",
            text: "Sub Content Brick draft has been created",
         });
         if (!isNavigating) {
            this.isRouteGuarded = false; // turn guards off in order to prevent infinite loop
            this.$router.replace({
               name: "sub-content-brick-detail",
               params: {
                  id: subContentBrick.id,
                  restoreTab: this.selectedTab,
               },
            });
         }
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API SubContentBrickDefinition create draft error:", error);

         if (error.message === "Invalid argument") {
            EventBus.$emit(Events.DisplayToast, {
               color: "error",
               text: `Cannot create new draft - a draft for Sub Content Brick {${this.subContentBrick.code}} already exists.`,
            });
            // Cannot create new draft - a draft for Content Brick {{ contentBrick.code }} already exists.
         } else {
            this.notifyError(error, "create", "Sub Content Brick draft");
         }
      }
      this.loading = false;
   }

   async updateSubContentBrickDefinition(versionNote?: string | null): Promise<void> {
      if (!this.subContentBrick) throw "No SubContentBrickDefinition defined";

      if (!this.validate()) {
         this.selectedTab = 0;
         this.notifyError({ message: "Validation failed.", success: false }, "update", "Sub Content Brick");
         return;
      }

      this.loading = true;

      if (this.hasChanges) {
         try {
            // Call the API
            let subContentBrick: SubContentBrickDefinition | null = null;

            if (this.originalStatus === EntityStatus.Draft) {
               //if status was Draft, save it, otherwise skip saving (discard all changes)
               subContentBrick = await SubContentBrickDefinitionApi.updateSubContentBrickDefinition(
                  this.id,
                  new SubContentBrickDefinition(this.subContentBrick)
               );
            }

            if (this.subContentBrick.entityStatus !== this.originalStatus) {
               //if status changed update status-only in separate step
               subContentBrick = await SubContentBrickDefinitionApi.updateSubContentBrickDefinitionStatus(
                  this.id,
                  new VersionedDocumentStatusChange({
                     newStatus: this.subContentBrick.entityStatus,
                     versionNote: versionNote ?? undefined,
                  })
               );
            }

            if (subContentBrick) {
               // if something was saved
               if (subContentBrick.id !== this.id) {
                  this.isRouteGuarded = false; // turn guards off in order to prevent infinite loop
                  this.$router.replace({
                     name: "sub-content-brick-detail",
                     params: {
                        id: subContentBrick.id,
                        restoreTab: this.selectedTab,
                     },
                  }); // new copy was created
               }

               // Process/Save data etc.
               this.setSubContentBrick(subContentBrick);
               EventBus.$emit(Events.DisplayToast, {
                  color: "success",
                  text: "Sub Content Brick has been updated",
               });
            }
         } catch (e) {
            let error = e as BaseResponse;
            console.warn("API SubContentBrickDefinition update error:", error);
            this.notifyError(error, "update", "Sub Content Brick");
         }
      }

      this.loading = false;
   }

   async deleteSubContentBrickDefinition(): Promise<void> {
      this.loading = true;
      try {
         if (!this.subContentBrick) throw "No SubContentBrickDefinition defined";

         await SubContentBrickDefinitionApi.deleteSubContentBrickDefinition(this.subContentBrick.id);
         EventBus.$emit(Events.DisplayToast, {
            color: "success",
            text: "Content Brick has been deleted",
         });
         this.isRouteGuarded = false; // turn guards off in order to prevent infinite loop
         this.$router.replace({ name: "sub-content-brick-list" });
      } catch (e) {
         let error = e as BaseResponse;
         console.warn("API SubContentBrickDefinition delete error:", error);
         this.notifyError(error, "delete", "Sub Content Brick");
      }
      this.loading = false;
   }

   async loadTranslations() {
      await this.loadRouteTranslations(this.routeNames);
      for (var tab of this.detailTabs) {
         tab.title = this.translateKey(tab.translationKey);
      }
   }

   mounted() {
      this.loadTranslations();
      EventBus.$on(Events.LanguageChanged, () => {
         this.loadTranslations();
      });
      this.$root.isDomainChangeDisabled = true;
      this.id = this.$route.params.id;
      this.code = this.$route.params.code;
      this.isNew = !this.id && !this.code;

      if (this.isNew) {
         const status = EntityStatus.Draft;
         this.subContentBrick = SubContentBrickDefinition.fromJS({
            entityStatus: status,
            domains: [GlobalStore.getDomain()],
            fields: [],
            contentBrickConditionResultsIn: ContentBrickConditionResultsIn.Error,
            condition: new ContentBrickExpression({
               expressionType: ContentBrickExpressionType.LogicGroupExpression,
               operator: "AND",
               operands: [],
               left: undefined,
               right: undefined,
            }),
         });
         this.originalStatus = status;
      } else {
         this.loadSubContentBrickDefinition();
         filterStore.addVisitedItem(this.id, EntityType.SubContentBrickDefinition);
      }
      ChangeWatcher.setupWatch(this, "subContentBrick", true);

      if (this.$route.params.restoreTab) {
         this.selectedTab = this.$route.params.restoreTab;
      }
   }

   beforeDestroy() {
      this.$root.isDomainChangeDisabled = false;
   }

   unmounted() {
      ChangeWatcher.destroyWatch(this, "subContentBrick");
   }
}
</script>
