<template>
   <div>
      <v-card class="mx-auto" flat>
         <v-row>
            <v-col cols="auto" class="pt-0 pb-0">
               <h6>
                  {{ title }}
               </h6>
            </v-col>
            <v-col v-if="loading" cols="auto" class="pa-0">
               <v-progress-circular indeterminate color="red" size="16"></v-progress-circular>
            </v-col>
         </v-row>

         <div class="tag-selector">
            <v-chip-group v-model="internalValue" :color="itemColor" column multiple>
               <v-chip v-for="item in enabledTags" :value="item" :key="item.id" filter @click="onChipClick" outlined>
                  {{ item.displayText }}
               </v-chip>
            </v-chip-group>
         </div>

         <div v-if="isNoDataTextShown" class="tag-cloud-no-data-text">
            <span>{{ noDataText }}</span>
         </div>
      </v-card>
   </div>
</template>

<script lang="ts">
import { IItemReference } from "@backend/api/pmToolApi";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import { Component, Prop, Watch } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";

@Component({
   components: {},
})
export default class TagCloud extends ComponentBase {
   @Prop({ default: () => [] })
   value: IItemReference[];

   @Prop({ default: () => [] })
   items: IItemReference[];

   @Prop({ default: "Value" })
   itemName: string;

   @Prop({ default: "red" })
   itemColor: string;

   @Prop({ default: false })
   loading: boolean;

   //-------------- Texts ---------------
   get title(): string {
      return `${this.translateKey("tagCloud.selectLabel")} ${this.itemName}s`;
   }

   get noDataText(): string {
      return `${this.translateKey("tagCloud.noItemsLabel")} ${this.itemName}s.`;
   }

   get isNoDataTextShown(): boolean {
      return !this.hasItems;
   }

   //--------- Value handling -----------

   get internalValue(): IItemReference[] {
      return this.value;
   }

   set internalValue(value: IItemReference[]) {
      this.updateValue(value);
   }

   updateValue(value: IItemReference[]) {
      this.$emit("input", value);
   }

   //----------- Chips --------------
   disabled: boolean = false;

   @Watch("loading", { deep: true })
   onValueChanged(): void {
      if (this.loading === false) {
         this.disabled = false;
      }
   }

   get enabledTags() {
      return this.items?.filter((t) => !this.isChipDisabled(t));
   }

   onChipClick() {
      this.disabled = true;
   }

   isChipDisabled(item: IItemReference): boolean {
      return (this.disabled && this.value.findIndex((s) => s.id === item.id) === -1) || item.disabled;
   }

   get hasItems(): boolean {
      return this.items && this.items.length > 0;
   }

   mounted() {
      this.loadRouteTranslations("tag-cloud");
      EventBus.$on(Events.LanguageChanged, () => this.loadRouteTranslations("tag-cloud"));
   }
}
</script>

<style scoped lang="scss">
.tag-cloud {
   &-no-data-text {
      height: 40px;
   }
}

.tag-selector {
   max-height: 6rem;
   overflow-y: auto;
}
</style>
